import { Show, Suspense, useContext, Match, Switch } from 'solid-js';
import { Button, Link } from '@troon/ui';
import { Meta, Title } from '@solidjs/meta';
import { IconStar, IconStarFilled } from '@troon/icons';
import { useAction } from '@solidjs/router';
import { Content } from '../../../components/content';
import { FacilityCtx } from '../../../providers/facility';
import { Grid, GridMain, GridSidebar } from '../../../components/layouts/grid';
import { Carousel } from '../../../components/carousel';
import { gql, mutationAction, useMutation } from '../../../graphql';
import { getFacilitySchema } from '../../../modules/schema/facility';
import { FacilityLocation } from '../../../components/facility/location';
import { Hero } from '../../../components/hero/photo';
import type { RouteDefinition } from '@solidjs/router';
import type { Address, Course, Facility } from '../../../graphql';

export default function Course() {
	const facility = useContext(FacilityCtx);
	const handleFavoriteAction = useAction(useMutation(handleFavorite));

	return (
		<>
			<Show when={facility()?.facility}>
				{(facility) => (
					<>
						<Title>{`${facility()?.name} | Troon Rewards | Book tee times`}</Title>
						<Meta
							name="description"
							content={`${facility()?.metadata?.description?.slice(0, 154) ?? 'Book tee times at your favorite courses with Troon'}…`}
						/>
						<script type="application/ld+json" innerText={getFacilitySchema(facility() as Facility)} />
					</>
				)}
			</Show>

			<Suspense fallback={<div>loading…</div>}>
				<Show when={facility()?.facility}>
					{(facility) => (
						<>
							<Hero src={facility().metadata?.hero?.url} />
							<Content>
								<header class="mb-6 flex flex-wrap items-center justify-start gap-4 border-b border-neutral-500 pb-6 md:mb-8 md:flex-nowrap md:pb-8 lg:gap-8">
									<div class="flex shrink grow flex-col gap-2 md:gap-4">
										<h1 class="text-3xl font-semibold lg:text-4xl">{facility().name}</h1>
										<Show when={facility().metadata?.address as Address}>
											{(address) => (
												<address
													class="text-sm not-italic text-neutral-800"
													aria-label={`Address for ${facility().name}`}
												>
													{[address().street, address().city, [address().state, address().postalCode].join(' ')].join(
														', ',
													)}
												</address>
											)}
										</Show>
									</div>

									<div class="flex grow basis-full flex-row justify-end gap-4 md:grow-0 md:basis-auto">
										<div class="grow lg:grow-0">
											<Button
												as={Link}
												href={`/course/${facility().slug!}/reserve-tee-time`}
												class="shrink text-nowrap"
											>
												Book a tee time
											</Button>
										</div>
										<div class="shrink">
											<Button
												appearance="secondary"
												onClick={() => {
													const data = new FormData();
													data.set('facilityId', facility().id ?? '');
													data.set('isFavorite', facility().isFavorite ? 'false' : 'true');
													handleFavoriteAction(data);
												}}
											>
												<Switch>
													<Match when={facility().isFavorite}>
														<IconStarFilled class="text-xl" />
													</Match>
													<Match when>
														<IconStar class="text-xl" />
													</Match>
												</Switch>
												<span class="sr-only">Favorite</span>
											</Button>
										</div>
									</div>
								</header>

								<Grid>
									<GridMain class="flex flex-col gap-8">
										<section class="flex flex-col gap-4">
											<h2 class="text-xl font-semibold md:text-2xl">Overview</h2>
											<p innerHTML={facility().metadata?.description ?? ''} />
										</section>

										<Show when={facility().metadata?.gallery.length}>
											<section class="flex flex-col gap-4">
												<h2 class="text-xl font-semibold md:text-2xl">Course photos</h2>
												<Carousel
													images={facility().metadata!.gallery.map(({ url }) => ({
														url,
														alt: `Landscape putting greens and fairway photo of the ${facility().name} golf course`,
													}))}
												/>
											</section>
										</Show>
									</GridMain>

									<GridSidebar>
										<div class="flex flex-col gap-8">
											<FacilityLocation facility={facility()} />
										</div>
									</GridSidebar>
								</Grid>
							</Content>
						</>
					)}
				</Show>
			</Suspense>
		</>
	);
}

const toggleFavorite = gql(`
mutation toggleFavoriteFacility($facilityId: String!, $isFavorite: Boolean!) {
	toggleFavoriteFacility(facilityId: $facilityId, isFavorite: $isFavorite) {
		name
		isFavorite
	}
}
`);

const handleFavorite = mutationAction(toggleFavorite, {
	transform: (data) => ({
		facilityId: data.get('facilityId') as string,
		isFavorite: data.get('isFavorite') === 'true',
	}),
	toast: (data) =>
		data?.toggleFavoriteFacility.isFavorite
			? `Added ${data?.toggleFavoriteFacility.name} as a favorite`
			: `Removed ${data?.toggleFavoriteFacility.name} as a favorite.`,
	revalidates: ['facility', 'home', 'facilities', 'searchFacilitiesList'],
});

export const route = { info: { hasHero: true } } satisfies RouteDefinition;
